import React from "react"
import {graphql} from "gatsby"

import GlobalLayout from "./global-layout"
import SEO from "../components/seo"
import PostExcerpt from "../components/post-excerpt"

const KeywordPageLayout = ({data, pageContext}) => {
  const pageTitle = `Keyword: "${pageContext.keyword}"`
  return (
    <GlobalLayout>
      <SEO
        title={pageTitle}
        meta={[{
          name: 'robots',
          content: 'noindex',
        }]}
      />
      <h3 className="page-label">{pageTitle}</h3>
      {data.allMdx.edges.map(({node}, idx) => {
        return (
          <PostExcerpt
            key={idx}
            createdDate={node.frontmatter.date}
            name={node.slug}
            title={node.frontmatter.title}
            excerpt={node.excerpt}
          />
        )
      })}
    </GlobalLayout>
  )
}

export const query = graphql`
query KeywordPageLayoutQuery($keyword: String) {
  allMdx(
    filter: {frontmatter: {keywords: {eq: $keyword}}}
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    edges {
      node {
        frontmatter {
          title
          date
        }
        slug
        excerpt(truncate: true)
      }
    }
  }
}
`

export default KeywordPageLayout
